import _ from "lodash";
import axios, { AxiosRequestConfig } from "axios";
import { handleFieldUpdates } from "../listeners/FieldChanged";
import AuthService from "./AuthService";
import { ELinkType, EWorkItemState, EWorkItemType } from "../Enum";
import EventEmitter from "events";
import { handleRefresh } from "../listeners/WorkItemRefreshed";
import { handleOnLoaded } from "../listeners/WorkItemLoaded";

class VSSService extends EventEmitter {
    public WorkItemRestClient: any;
    public WorkItemServices: any;

    constructor() {
        super();
        VSS.require(["TFS/WorkItemTracking/RestClient", "TFS/WorkItemTracking/Services"], (WorkItemRestClient: any, WorkItemServices: any) => {
            this.WorkItemRestClient = WorkItemRestClient;
            this.WorkItemServices = WorkItemServices;
            VSS.notifyLoadSucceeded();
        });

        VSS.register("sample-work-item-form-group", function () {
            return {
                onFieldChanged: function (args) {
                    handleFieldUpdates(args);
                },
                onRefreshed: function () {
                    handleRefresh();
                },
                onLoaded: function (args) {
                    handleOnLoaded(args);
                }
            }
        });
    }

    get theme(): string {
        return localStorage.getItem('azureUserTheme') || '';
    }

    async getUserTheme(): Promise<string> {
        try {
            const options: AxiosRequestConfig = {
                url: 'https://dev.azure.com/groupxs/_apis/Settings/Entries/me/WebPlatform',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  `Basic ${Buffer.from(`PAT:${AuthService.pat}`).toString('base64')}`,
                }
            };
            const response = await axios.request(options);

            localStorage.setItem('azureUserTheme', response.data.value["Theme"]);
            return response.data.value["Theme"];
        } catch(error) {
            console.warn(`Unable to get user theme: ${error}`);
            return 'ms.vss-web.vsts-theme';
        }
    }

    async getWorkItemRestClient(): Promise<any> {
        while (!this.WorkItemRestClient) {
            await new Promise(res => setTimeout(res, 1000));
        }
        return await this.WorkItemRestClient.getClient();
    }

    async getWorkItemFormService(): Promise<any> {
        while (!this.WorkItemServices) {
            await new Promise(res => setTimeout(res, 1000));
        }
        return await this.WorkItemServices.WorkItemFormService.getService();
    }

    async getCurrentWorkItemFieldValue(fieldName: string): Promise<any> {
        const workItemContextService = await this.getWorkItemFormService();
        const fields = await workItemContextService.getFields();
        const foundField = _.find(fields, field => field.referenceName == fieldName);
        if (foundField) {
            const fieldObject = await workItemContextService.getFieldValues([foundField.referenceName]);
            return fieldObject[fieldName];
        }
        return '';
    }

    async getCurrentWorkItemState(): Promise<EWorkItemState> {
        const workItemContextService = await this.getWorkItemFormService();
        const workItemInfo = await workItemContextService.getFieldValues(["System.State"]);
        return workItemInfo["System.State"];
    }

    async getCurrentWorkItemType(): Promise<EWorkItemType> {
        const workItemContextService = await this.getWorkItemFormService();
        const workItemInfo = await workItemContextService.getFieldValues(["System.WorkItemType"]);
        return workItemInfo["System.WorkItemType"];
    }

    async getCurrentWorkItemId(): Promise<number> {
        const workItemContextService = await this.getWorkItemFormService();
        const workItemInfo = await workItemContextService.getFieldValues(["System.Id"]);
        return workItemInfo["System.Id"];
    }

    async getWorkItemById(workItemId: number): Promise<any> {
        const workItemRestClient = await this.getWorkItemRestClient();
        const workItem = await workItemRestClient.getWorkItem(workItemId, null, null, 4);
        return workItem;
    }

    async getWorkItemTypeById(workItemId: number): Promise<EWorkItemType> {
        const workItemRestClient = await this.getWorkItemRestClient();
        const workItem = await workItemRestClient.getWorkItem(workItemId, null, null, 4);
        return workItem.fields["System.WorkItemType"];
    }

    async getWorkItemRelations(): Promise<any> {
        const workItemContextService = await this.getWorkItemFormService();
        const workItemRelations = await workItemContextService.getWorkItemRelations();
        return workItemRelations;
    }

    async getWorkItemFieldValue(workItemId: number, field: string): Promise<any> {
        const workItemRestClient = await this.getWorkItemRestClient();
        const workItem = await workItemRestClient.getWorkItem(workItemId, null, null, 4);
        return workItem.fields[field];
    }

    async getLinkedWorkItems(linkedAs: ELinkType, workItemTypes: EWorkItemType[]): Promise<{ id: number, projectName: string, type: EWorkItemType, state: EWorkItemState }[]> {
        const workItemContextService = await this.getWorkItemFormService();
        const workItemRelations = await workItemContextService.getWorkItemRelations();

        const linkedWorkItemsInfo = await Promise.all(
            _.map(workItemRelations, async relation => {
                if (relation.rel == linkedAs) {
                    const linkedId = this.getIdFromRelationUrl(relation.url);
                    const workItem = await this.getWorkItemById(linkedId);
                    const projectName = await this.getWorkItemProjectName(linkedId);
                    const workItemType = workItem.fields["System.WorkItemType"];
                    const workItemState = workItem.fields["System.State"];

                    if (_.includes(workItemTypes, workItemType)) {
                        return {
                            id: linkedId,
                            projectName: projectName,
                            type: workItemType,
                            state: workItemState
                        }
                    }
                }
            })
        );

        return _.compact(linkedWorkItemsInfo);
    }

    async getWorkItemsSetToStates(linkedAs: ELinkType, workItemTypes: EWorkItemType[], states: EWorkItemState[]): Promise<{ id: number, projectName: string, type: EWorkItemType, state: EWorkItemState }[]> {
        const linkedWorkItems = await this.getLinkedWorkItems(linkedAs, workItemTypes);

        const workItems = _.map(linkedWorkItems, workItem => {
            if (_.includes(states, workItem.state)) {
                return workItem;
            }
        })

        return _.compact(workItems);
    }

    async getWorkItemsNotSetToStates(workItemTypes: EWorkItemType[], linkedAs: ELinkType, notSetToStates: EWorkItemState[]): Promise<{ id: number, projectName: string, type: EWorkItemType, state: EWorkItemState }[]> {
        const linkedWorkItems = await this.getLinkedWorkItems(linkedAs, workItemTypes);

        const workItems = _.map(linkedWorkItems, workItem => {
            if (!_.includes(notSetToStates, workItem.state)) {
                return workItem;
            }
        })

        return _.compact(workItems);
    }

    async getWorkItemProjectName(workItemId: number): Promise<any> {
        const workItemRestClient = await this.getWorkItemRestClient();
        const workItem = await workItemRestClient.getWorkItem(workItemId, null, null, 4);

        const titleFull = workItem.fields['System.Title'];

        const pattern = /^\[(\w+)\]\s*(.{0,99})$/;
        const matches = titleFull.match(pattern);
        if (matches && matches.length >= 2) {
            const projectName = matches[1];
            return projectName;
        }
        return '';
    }

    private getIdFromRelationUrl(url: string): number {
        const idMatches = url.match(/(\d+)$/);
        if (idMatches && idMatches.length == 2) {
            return parseInt(idMatches[1]);
        }
        return 0;
    }
}

const vssservice = new VSSService();
export default vssservice;